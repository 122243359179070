<template>
  <a :href="link" class="logo" :title="title">{{ text }}</a>
</template>
<script>
export default {
  name: 'LogoBlock',
  data() {
    return {
      'link': '/',
      'title': 'Sticker Print - Напечатаем ваши этикетки на термонаклейках',
      'text': 'Sticker Print'
    }
  }
}
</script>
<style lang="scss">
.logo {
  font-weight: 700;
  font-size: 30px;
  line-height: 20px;
  color: $c_green;
}
</style>