<template>
  <div class="header">

    <MainScreen>
      <div class="header__top">

        <div class="header__logo">
          <LogoBlock></LogoBlock>
        </div>

      </div>
    </MainScreen>

  </div>
</template>
<script>

import MainScreen from './Header/MainScreen.vue';
import LogoBlock from './Header/LogoBlock.vue';

export default {
  name: 'HeaderBlock',
  components: {
    MainScreen,
    LogoBlock
  },
  props: {}
}
</script>

<style lang="scss">
.header {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 0 57px;
  }

  &__menu {
    flex-grow: 2;
  }

  &__button {
    flex-shrink: 0;
  }

  &__logo {
    flex-shrink: 0;
  }
}
</style>