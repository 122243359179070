<template>
  <div class="main-screen">

    <div class="main-screen__inner">
      <slot></slot>
      <h1>Напечатаем ваши этикетки на&nbsp;термонаклейках</h1>
      <div class="main-screen__content">
        <div class="main-screen__row">
          <div class="main-screen__left">
            <div class="main-screen__title">Кому будем полезны?</div>
            <div class="main-screen__text">
              Поставщикам маркетплейсов, которые еще не обзавелись необходимым оборудованием для маркировки товаров.
            </div>
            <div class="main-screen__icons">
              <img src="/assets/images/icons.png">
              <img class="main-screen__icons-wave" src="/assets/svg/ms-wave.svg">
            </div>
          </div>
          <div class="main-screen__right">
            <div class="main-screen__images">
              <img class="main-screen__image-1" src="/assets/images/ms-1.png" alt="">
              <img class="main-screen__image-2" src="/assets/images/ms-2.png" alt="">
              <img class="main-screen__image-3" src="/assets/images/ms-3.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'MainScreen',
  props: {}
}
</script>

<style lang="scss">
.main-screen {
  min-height: 100vh;
  padding: 10px;
  display: flex;

  h1 {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
  }

  &__inner {
    width: 100%;
    background-color: $c_blue;
    border-radius: 60px;
    padding: 37px 60px;
  }

  &__content {

  }

  &__row {
    display: flex;
    grid-gap: 0 20px;
    justify-content: center;
    align-items: center;
  }

  &__left {
    width: 420px;
    flex-shrink: 0;
  }

  &__right {

  }

  &__icons {
    z-index: 1;
    position: relative;
  }

  &__icons-wave {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 12px);
    transform: translate(-50%, -50%);
  }

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    color: #000000;
    margin-bottom: 20px;
    position: relative;
    z-index: 2;
  }

  &__text {
    margin-bottom: 40px;
    position: relative;
    z-index: 2;
  }

  &__images {
    position: relative;
    width: 860px;
    display: flex;
    justify-content: center;
  }

  &__image-1 {
    position: absolute;
    top: 50%;
    left: calc(50% - 178px);
    transform: translate(-50%, -50%);
    z-index: 1;
    filter: drop-shadow(0px 22px 130px rgba(0, 0, 0, 0.07)) drop-shadow(0px 4.91399px 29.0372px rgba(0, 0, 0, 0.0350347)) drop-shadow(0px 1.46302px 8.64513px rgba(0, 0, 0, 0.0370634));
  }

  &__image-2 {
    position: relative;
    z-index: 3;
    filter: drop-shadow(0px 22px 130px rgba(0, 0, 0, 0.07)) drop-shadow(0px 4.91399px 29.0372px rgba(0, 0, 0, 0.0350347)) drop-shadow(0px 1.46302px 8.64513px rgba(0, 0, 0, 0.0370634));
  }

  &__image-3 {
    position: absolute;
    top: 50%;
    right: calc(50% - 200px);
    transform: translate(50%, -50%);
    z-index: 1;
    filter: drop-shadow(0px 22px 130px rgba(0, 0, 0, 0.07)) drop-shadow(0px 4.91399px 29.0372px rgba(0, 0, 0, 0.0350347)) drop-shadow(0px 1.46302px 8.64513px rgba(0, 0, 0, 0.0370634));
  }
}

@media (max-width: $lg) {
  .main-screen {
    &__images {
      zoom: 0.8;
    }
  }
}

@media (max-width: $md) {
  .main-screen {
    &__images {
      zoom: 0.6;
    }
  }
}
</style>
