<template>
  <div class="gray-block">
    <div class="order">
      <h2 class="order__title">Получить расчет стоимости</h2>
      <p>
        Мы печатаем наклейки в двух размерах:
      </p>
      <ol>
        <li>58x40мм</li>
        <li>75x120мм</li>
      </ol>
      <p>
        Минимальная стоимость заказа будет равна одному ролику (58х40 - 500 этикеток, 75x120 - 300 этикеток)
      </p>
      <h3>
        Напишите нам на почту следующую информацию:
      </h3>
      <div>
        <ol>
          <li>Файл PDF или XLSX с необходимыми наклейками;</li>
          <li>Укажите количество наклеек:</li>
          <ul>
            <li>PDF-файл - количество будет равно количеству наклеек в файле, либо укажите точное количество каждой
              наклейки;
            </li>
            <li>XLSX-файл - укажите точное количество каждой наклейки;</li>
          </ul>
          <li>Укажите необходимый размер наклеек: 58x40 или 75x120 (печатаем только этим размеры);</li>
          <li>Адрес доставки для отправки транспортной компанией CDEK</li>
          <ul>
            <li>Пункт ПВЗ</li>
            <li>или Точный адрес для доставки до двери</li>
          </ul>
          <li>Ваш аккаунт в Telegram/Whatsapp для быстрой связи;</li>
        </ol>
      </div>
      <p>После получения всей необходимой информации мы свяжемся для согласования данных и стоимости заказа</p>
      <h2>Наш E-mail для отправки заявки</h2>
      <h3>manager@sticker-print.ru</h3>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BetaBlock',
  components: {},
  props: {}
}
</script>

<style lang="scss">

.order {
  max-width: 1000px;
  margin: 0 auto;
  line-height: 40px;

  &__title {
    margin-top: 0;
  }
}

</style>