<template>
  <HeaderBlock/>
  <BetaBlock/>
</template>

<script>
import HeaderBlock from '../../components/HeaderBlock.vue'
import BetaBlock from '../../components/BetaBlock.vue'

export default {
  name: 'App',
  components: {
    HeaderBlock,
    BetaBlock
  }
}
</script>

<style>

</style>
